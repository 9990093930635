<template>
    <ion-page data-pageid="home">
        <ion-content :scroll-events="false">
            <ChinhSuaThongTinCaNhanVue
                TieuDe="Email"
                :model="model.Email"
                keyChinhSua="email"
            >
                <template #content>
                    <DxValidationGroup ref="formValidation">
                        <span class="font-12">Email (*)</span>
                        <DxTextBox
                            class="mb-3"
                            v-model="model.Email"
                            validationMessageMode="always"
                            styling-mode="underlined"
                            ref="refTab1"
                            mode="email"
                        >
                            <DxValidator ref="refValidateEmail"
                                ><DxEmailRule
                                    message="Email không đúng định dạng!"
                                />
                                <DxStringLengthRule
                                    :max="150"
                                    message="Email không được vượt quá 150 ký tự!"
                                /> </DxValidator
                        ></DxTextBox>
                    </DxValidationGroup>
                </template>
            </ChinhSuaThongTinCaNhanVue>
        </ion-content>
    </ion-page>
</template>
<script>
import { DxEmailRule, DxStringLengthRule } from "devextreme-vue/data-grid";
import ChinhSuaThongTinCaNhanVue from "../../../src/components/_Common/ChinhSuaThongTinCaNhan";
import { DxTextBox, DxValidationGroup, DxValidator } from "devextreme-vue";
import { IonContent, IonPage } from "@ionic/vue";
export default {
    components: {
        ChinhSuaThongTinCaNhanVue,
        DxValidationGroup,
        DxTextBox,
        DxValidator,
        DxEmailRule,
        DxStringLengthRule,
        IonContent,
        IonPage,
    },
    props: {},
    data() {
        return {
            model: { Email: "" },
        };
    },
    methods: {},
    created() {},
    mounted() {
        let refSdt = this.$refs.refTab1.instance;
        if (refSdt) {
            refSdt.focus();
        }
        this.model.Email = this.$route.query.data;
        console.log("🚀 ~ file: email.vue ~ line 65 ~ mounted ~ this.model.Email", this.model.Email)
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>